import add from "date-fns/add";
import { Field } from "../../features/pricing_list/pricingListSlice";
import * as yup from "yup";

export const partySchema = (rowsCount: number) => {
	let schema = {};

	for (let i = 0; i < rowsCount; i++) {
		schema = {
			...schema,
			[`party${i}`]: yup.object({
				id: yup.number().required("Party id is a required field"),
				partyId: yup.number().required("Party id is a required field"),
				shortName: yup
					.string()
					.required("Party shortName is a required field"),
				name: yup.string().required("Party name is a required field"),
			}),
			[`percentage${i}`]: yup
				.number()
				.required("Percentage is a required field"),
		};
	}
	return yup.object(schema);
};

const tomorrowDate = add(new Date(new Date().setHours(0, 0, 0, 0)), {
	days: 1,
});
export const validityDateSchema = yup.object({
	newValidityDate: yup
		.date()
		.required("Validity date is a required field")
		.min(tomorrowDate, "Only future dates are allowed ( > Today)"),
});

const ProxyRefSchema = yup.object({
	proxy_refs_array: yup.array().min(1, "Tourbillion Ref is a required field"),
});

export const useActionFieldValidation = (
	fields: { [key: string]: Field },
	rowsCount: number = 1
) => {
	const valueValidation = (yupValidation: any, field: Field) => {
		if (field.type === "text") {
			return yupValidation.string();
		}
		if (field.type === "number") {
			let schema = yupValidation
				.number()
				.transform((value: any) => (Number.isNaN(value) ? null : value))
				.nullable();
			if (field.must_be_positive) {
				schema = schema.min(0);
			}
			if (field.required) {
				schema = schema.required();
			} else {
				schema = schema.notRequired();
			}
			return schema;
		}
		if (field.type === "date") {
			return yupValidation.date();
		}
		return yupValidation;
	};

	const requiredValidation = (yupValidation: any, required: boolean) => {
		if (required) {
			return yupValidation.required();
		}
		return yupValidation;
	};

	if (fields.party) {
		return partySchema(rowsCount);
	}
	if (fields.proxy_refs) {
		return ProxyRefSchema;
	}
	if (fields.newValidityDate) return validityDateSchema;

	const validationObject: any = {};
	for (const key in fields) {
		const typeValidation = valueValidation(yup, fields[key]);
		if (fields[key].is_array) {
			validationObject[key] = yup
				.array()
				.min(1, "Please provide at least one item")
				.of(typeValidation.required("Please provide a value"))
				.test("unique", "Items must be unique", (value) => {
					if (!value) return true;

					const unique = value.filter(
						(v: any, i: number, a: any) =>
							a.findIndex((t: any) => t === v) === i
					);
					return unique.length === value.length;
				});
		} else {
			validationObject[key] = requiredValidation(
				typeValidation,
				fields[key].required
			);
		}
	}
	return yup.object(validationObject);
};
